import React from "react"
import Layout from "../components/layout"
// import "react-responsive-carousel/lib/styles/carousel.min.css"
import TechnicalData from "../components/product_tech_data"
import ProductDetail from "../components/product_detail"
import desilting from "../../public/static/images/products/desilting.jpg"

function Desilting() {

  const prodDetail = {
    "name": "DESILTING",
    "detail": {
      "about" : ["Desilting Machine is developed on a three-wheeler and on TATA ACE. Used for effective and efficient cleaning of silt from manholes and wet wells/sump house, our quality machines have helped us garner a rich clientele in the market. Owing to quality parameters such as long functional life, reliable performance, corrosion & abrasion resistance and low maintenance, these machines are hugely demanded in the market."],
      "advantages": [
        "No human touch, 100% hygienic, no life risk",
        "No need to enter into manholes",
        "The shorter turning radius helps in moving easily in narrow lanes/by lanes",
        "It helps in fast operation",
        "It is economical and available at a competitive"
      ]
    }
  }

  return (
    <Layout className="semi-automatic">
      <ProductDetail name={prodDetail.name} detail={prodDetail.detail} image={desilting} />

      {prodDetail.tech_data !== undefined ? <TechnicalData techData={prodDetail.tech_data} /> : <></>}
    </Layout>
  )
}

export default Desilting
